import React from 'react';
import { motion } from 'framer-motion';

import VSCIcon from '../assets/appsused/vsc.svg';
import AndroidIcon from '../assets/appsused/android.png';
import FigmaIcon from '../assets/appsused/figma.svg';
import TeamsIcon from '../assets/appsused/teams.svg';
import NotionIcon from '../assets/appsused/notion.svg';
import PyCharmIcon from '../assets/appsused/pycharm.svg';
import EclipseIcon from '../assets/appsused/eclipse.svg';
import ClickUpIcon from '../assets/appsused/clickup.svg';

const techApps = [
  { 
    id: 1, 
    name: 'Visual Studio Code', 
    icon: VSCIcon,
    description: 'Editor de texto multiplataforma con soporte para extensiones, ideal para desarrollo web, Node, etc.',
    url: 'https://code.visualstudio.com/'
  },
  { 
    id: 2, 
    name: 'Android Studio', 
    icon: AndroidIcon,
    description: 'Entorno de desarrollo integrado (IDE) oficial para crear apps Android.',
    url: 'https://developer.android.com/studio'
  },
  { 
    id: 3, 
    name: 'Figma', 
    icon: FigmaIcon,
    description: 'Herramienta colaborativa para diseñar interfaces de usuario y prototipos.',
    url: 'https://www.figma.com/'
  },
  { 
    id: 4, 
    name: 'Teams', 
    icon: TeamsIcon,
    description: 'Aplicación de comunicación empresarial y colaboración en equipo.',
    url: 'https://www.microsoft.com/microsoft-teams/'
  },
  { 
    id: 5, 
    name: 'Notion', 
    icon: NotionIcon,
    description: 'Plataforma para organización personal y de equipo, con notas, bases de datos y más.',
    url: 'https://www.notion.com/es-es'
  },
  { 
    id: 6, 
    name: 'PyCharm', 
    icon: PyCharmIcon,
    description: 'IDE especializado en desarrollo Python, con soporte para Django, Flask y más.',
    url: 'https://www.jetbrains.com/es-es/pycharm/'
  },
  { 
    id: 7, 
    name: 'Eclipse', 
    icon: EclipseIcon,
    description: 'Entorno de desarrollo integrado para Java y otros lenguajes de programación.',
    url: 'https://www.eclipse.org/downloads/'
  },
  { 
    id: 8, 
    name: 'ClickUp', 
    icon: ClickUpIcon,
    description: 'Herramienta de gestión de proyectos que puede utilizarse para diversas tareas, desde la gestión de un equipo hasta el seguimiento de objetivos personales.',
    url: 'https://clickup.com'
  },
];

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      delayChildren: 0.2,
    },
  },
};

const cardVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      stiffness: 120,
      damping: 12,
    },
  },
};

const Tech = () => {
  return (
    <motion.div
      className="p-6 bg-transparent rounded-lg shadow-lg"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <motion.h2
        className="text-3xl font-bold text-pastel-yellow mb-8"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.6 }}
      >
        Aplicaciones que utilizo
      </motion.h2>

      <motion.div
        className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4"
        variants={containerVariants}
      >
        {techApps.map((app) => (
        <motion.div key={app.id} variants={cardVariants}>
            <TechCard 
                name={app.name} 
                icon={app.icon} 
                description={app.description} 
                url={app.url} 
            />
        </motion.div>
        ))}
      </motion.div>
    </motion.div>
  );
};

export default Tech;

const TechCard = ({ name, icon, description, url }) => {
    const [isFlipped, setIsFlipped] = React.useState(false);
  
    return (
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="group perspective no-underline"
        onMouseEnter={() => setIsFlipped(true)}
        onMouseLeave={() => setIsFlipped(false)}
      >
        <motion.div
          className="relative w-full h-40 md:h-48 lg:h-56"
          animate={{ rotateY: isFlipped ? 180 : 0 }}
          transition={{ duration: 0.6 }}
          style={{
            transformStyle: 'preserve-3d',
          }}
        >
          <FrontSide name={name} icon={icon} />
          <BackSide name={name} description={description} />
        </motion.div>
      </a>
    );
  };

const FrontSide = ({ name, icon }) => {
  return (
    <div
      className="absolute w-full h-full bg-neutral-800 rounded-lg shadow-lg 
                 flex flex-col items-center justify-center
                 backface-hidden"
      style={{
        backfaceVisibility: 'hidden',
      }}
    >
      <motion.img
        src={icon}
        alt={name}
        className="w-12 h-12 mb-3"
        style={{ animation: 'float 3s ease-in-out infinite' }}
        initial={{ scale: 0.8 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.3 }}
      />
      <span className="text-lg font-medium text-white">{name}</span>
    </div>
  );
};

const BackSide = ({ name, description }) => {
  return (
    <div
      className="absolute w-full h-full bg-neutral-800 rounded-lg shadow-lg 
                 flex flex-col items-center justify-center
                 transform rotateY-180 backface-hidden"
      style={{
        backfaceVisibility: 'hidden',
        transform: 'rotateY(180deg)',
      }}
    >
      <motion.span
        className="text-md font-semibold text-white px-4 text-center leading-relaxed"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2, duration: 0.4 }}
      >
        {description}
      </motion.span>
    </div>
  );
};
