import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import FaceTracekr from '../assets/wip/facetracker.png';
import visualNovelImg from '../assets/wip/visualnovel.png';


// -----------------------------------------------------------------------------------
// 1. Datos principales de proyectos en progreso
// -----------------------------------------------------------------------------------
const wipProjects = [
  {
    id: 1,
    title: '3D Face Tracker',
    description:
      'Face Tacker diseñado con Numpy, CV2 y mediapipe.',
    image: FaceTracekr,
  },
  {
    id: 2,
    title: 'Visual Novel',
    description:
      'Novela visual, en fase de construccion de historia.',
    image: visualNovelImg,
  },
]

// -----------------------------------------------------------------------------------
// 2. Datos y variantes para las formas flotantes y estrellas
// -----------------------------------------------------------------------------------
const shapesData = [
  { id: 1, shape: 'circle', size: 100, color: '#FCD34D' },
  { id: 2, shape: 'square', size: 70, color: '#F87171' },
  { id: 3, shape: 'triangle', size: 90, color: '#34D399' },
  { id: 4, shape: 'circle', size: 60, color: '#60A5FA' },
  { id: 5, shape: 'square', size: 80, color: '#C084FC' },
]

const shapeVariants = {
  initial: {
    opacity: 0,
    scale: 0,
  },
  animate: {
    opacity: 0.7,
    scale: 1,
    rotate: [0, 360],
    transition: {
      duration: 10,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatType: 'reverse',
    },
  },
}

const starCount = 10

const starVariants = {
  hidden: { opacity: 0, scale: 0 },
  visible: {
    opacity: 1,
    scale: [1, 1.2, 1],
    transition: {
      duration: 1.5,
      ease: 'easeInOut',
      repeat: Infinity,
      repeatType: 'mirror',
    },
  },
}

// -----------------------------------------------------------------------------------
// 3. Variantes para animaciones de contenedor y tarjetas
// -----------------------------------------------------------------------------------
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15,
      delayChildren: 0.2,
    },
  },
}

const letterVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: (i) => ({
    opacity: 1,
    y: 0,
    transition: {
      delay: i * 0.03,
      type: 'spring',
      stiffness: 200,
      damping: 12,
    },
  }),
}

const subtitleVariants = {
  hidden: { opacity: 0, x: 20 },
  visible: {
    opacity: 1,
    x: 0,
    transition: { duration: 0.8 },
  },
}

const cardVariants = {
  hidden: { opacity: 0, y: 30 },
  visible: {
    opacity: 1,
    y: 0,
    transition: { duration: 0.7, ease: 'easeOut' },
  },
  hover: {
    scale: 1.03,
    rotate: 1,
    boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
  },
}

// -----------------------------------------------------------------------------------
// 4. Componente principal WipProjects
// -----------------------------------------------------------------------------------
const WipProjects = () => {
  return (
    <motion.div
      className="relative w-full min-h-screen bg-custom-bg text-gray-300 overflow-hidden flex flex-col items-center justify-center px-4 py-8"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      {shapesData.map((shape) => (
        <FloatingShape key={shape.id} shape={shape} />
      ))}
      <Sparkles />

      <div className="z-10 max-w-5xl w-full flex flex-col items-center">
        <AnimatedTitle text="Proyectos en Progreso" />

        <motion.p
          className="text-center text-xl md:text-2xl mt-4 mb-8 text-pastel-yellow"
          variants={subtitleVariants}
        >
          ¡Aquí podrás ver en lo que estoy trabajando actualmente!
        </motion.p>

        <motion.div
          className="flex flex-wrap gap-6 w-full justify-center items-center"
          variants={containerVariants}
        >
          <AnimatePresence>
            {wipProjects.map((project) => (
              <motion.div
                key={project.id}
                className="bg-neutral-800 rounded-lg shadow-lg p-4 flex flex-col items-center justify-between 
                          text-center w-64 h-80"
                variants={cardVariants}
                whileHover="hover"
                initial="hidden"
                animate="visible"
                exit="hidden"
              >
                <img
                  src={project.image}
                  alt={project.title}
                  className="rounded-md mb-4 w-full h-40 object-cover"
                />
                <h3 className="text-lg font-bold mb-2">{project.title}</h3>
                <p className="text-sm mb-4 text-gray-300">
                  {project.description}
                </p>
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </motion.div>
  )
}

export default WipProjects

// -----------------------------------------------------------------------------------
// 5. Sub-componentes para el fondo animado y el título
// -----------------------------------------------------------------------------------

const AnimatedTitle = ({ text }) => {
  const letters = Array.from(text)
  return (
    <div className="flex justify-center">
      <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold flex flex-wrap justify-center">
        {letters.map((char, i) => (
          <motion.span
            key={i}
            className="inline-block mr-[1px]"
            variants={letterVariants}
            custom={i}
          >
            {char === ' ' ? '\u00A0' : char}
          </motion.span>
        ))}
      </h1>
    </div>
  )
}
const FloatingShape = ({ shape }) => {
  const { shape: shapeType, size, color } = shape
  const randomTop = Math.floor(Math.random() * 80) + 10
  const randomLeft = Math.floor(Math.random() * 80) + 10

  let shapeStyles = {}
  if (shapeType === 'circle') {
    shapeStyles = {
      borderRadius: '9999px',
      width: `${size}px`,
      height: `${size}px`,
    }
  } else if (shapeType === 'square') {
    shapeStyles = {
      width: `${size}px`,
      height: `${size}px`,
    }
  } else if (shapeType === 'triangle') {
    shapeStyles = {
      width: '0',
      height: '0',
      borderLeft: `${size / 2}px solid transparent`,
      borderRight: `${size / 2}px solid transparent`,
      borderBottom: `${size}px solid ${color}`,
      backgroundColor: 'transparent',
    }
  }

  return (
    <motion.div
      className="absolute opacity-60"
      style={{
        top: `${randomTop}%`,
        left: `${randomLeft}%`,
        backgroundColor: shapeType === 'triangle' ? 'transparent' : color,
        zIndex: 1,
        ...shapeStyles,
      }}
      variants={shapeVariants}
      initial="initial"
      animate="animate"
    />
  )
}

const Sparkles = () => {
  const stars = Array.from({ length: starCount }, (_, i) => i)

  return (
    <div className="pointer-events-none absolute top-0 left-0 w-full h-full z-20">
      <AnimatePresence>
        {stars.map((star) => (
          <motion.span
            key={star}
            className="absolute w-2 h-2 bg-pastel-yellow rounded-full shadow-lg"
            style={{
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
            }}
            variants={starVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
          />
        ))}
      </AnimatePresence>
    </div>
  )
}
