import React from 'react';
import KotlinIcon from '../assets/tech/kotlin.png';
import SqlIcon from '../assets/tech/sql.png';
import CIcon from '../assets/tech/c.png';
import CssIcon from '../assets/tech/css3.png';
import OracleIcon from '../assets/tech/oracle.png';
import PythonIcon from '../assets/tech/python.png';
import JavaIcon from '../assets/tech/java.png';
import HtmlIcon from '../assets/tech/html5.png';
import ReactIcon from '../assets/tech/react.png';
import TailwindIcon from '../assets/tech/tailwind.png';
import GitIcon from '../assets/tech/git.png';
import GitHubIcon from '../assets/tech/github.png';
import MongoDBIcon from '../assets/tech/mongodb.png';
import JSIcon from '../assets/tech/js.png';
import PandasIcon from '../assets/tech/pandas.svg';
import MaterializeIcon from '../assets/tech/materialize.svg';

const Tech = () => {
  return (
    <div className="p-6 bg-transparent rounded-lg shadow-lg">
      <h2 className="text-3xl font-bold text-pastel-yellow mb-4">Tecnologías</h2>
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        <TechCard name="Java" icon={JavaIcon} />
        <TechCard name="Python" icon={PythonIcon} />
        <TechCard name="C#" icon={CIcon} />
        <TechCard name="Kotlin" icon={KotlinIcon} />
        <TechCard name="HTML" icon={HtmlIcon} />
        <TechCard name="CSS" icon={CssIcon} />
        <TechCard name="Oracle" icon={OracleIcon} />
        <TechCard name="SQL" icon={SqlIcon} />
        <TechCard name="React" icon={ReactIcon} />
        <TechCard name="Tailwind" icon={TailwindIcon} />
        <TechCard name="Git" icon={GitIcon} />
        <TechCard name="GitHub" icon={GitHubIcon} />
        <TechCard name="MongoDB" icon={MongoDBIcon} />
        <TechCard name="Materialize" icon={MaterializeIcon} />
        <TechCard name="JavaScript" icon={JSIcon} />
        <TechCard name="Pandas" icon={PandasIcon} />
      </div>
    </div>
  );
};

const TechCard = ({ name, icon }) => {
  return (
    <div className="flex flex-col items-center p-4 bg-neutral-800 rounded-lg shadow-md transition-transform transform hover:scale-105 hover:shadow-xl hover:rotate-3">
      <div className="text-5xl mb-2">
        {icon ? <img src={icon} alt={name} className="w-12 h-12" /> : <span className="text-gray-400">No icon</span>}
      </div>
      <span className="text-lg font-medium text-white">{name}</span>
    </div>
  );
};

export default Tech;
